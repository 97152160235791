<template>
  <div>
    <div class="home-banner">
      <img src="../../assets/logo.png"
           class="logo" />
    </div>
    <div class="container">
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active"
                aria-current="page">
              {{ code == "rules" ? "政策规定" : "通知公告" }}
            </li>
          </ol>
          <!-- 搜索框 -->
          <SearchBar @toSearch="toSearch"></SearchBar>
        </nav>
        <!-- 轮播图 -->
        <div id="carouselExampleCaptions"
             class="carousel slide"
             data-bs-ride="false">
          <div class="carousel-indicators"
               v-if="banners.length > 1">
            <button type="button"
                    data-bs-target="#carouselExampleCaptions"
                    :data-bs-slide-to="index"
                    :class="[index == curIdx ? 'active' : '']"
                    aria-current="true"
                    :aria-label="item.banner_id"
                    v-for="(item, index) in banners"
                    :key="item.expert_id"
                    @click="changeIndicator(index)"></button>
          </div>
          <div class="carousel-inner">
            <div :class="['carousel-item', index == curIdx ? 'active' : '']"
                 v-for="(item, index) in banners"
                 :key="item.paper_id"
                 @click="toDetail(item.paper_id)">
              <div class="company-news-item">
                <div class="company-news-title">
                  {{ item.title }}
                </div>
                <div class="company-news-time">{{ item.created_at }}</div>
              </div>

            </div>
            <button v-if="banners.length > 1"
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev"
                    @click="curIdx == 0 ? banners.length - 1 : curIdx--">
              <span class="carousel-control-prev-icon"
                    aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button v-if="banners.length > 1"
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next"
                    @click="curIdx == banners.length - 1 ? 0 : curIdx++">
              <span class="carousel-control-next-icon"
                    aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <!-- 选项卡 -->
        <!-- <ul class="nav nav-pills nav-blue" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" v-for="(item) in categorys" :key="item.paper_type_id">
            <button :class="['nav-link', item.paper_type_id==curCategory.paper_type_id?'active':'']" id="home-tab"
              data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab"
              aria-controls="home-tab-pane" aria-selected="true" @click="changeCategory(item)">
              {{item.type_name}}
            </button>
          </li>
        </ul> -->
        <div class="company-news-list">
          <!-- href="/companyarticle" -->
          <div class="company-news-item"
               v-for="item in dataList.list"
               :key="item.paper_id"
               @click="toDetail(item.paper_id)">
            <div class="company-news-title">
              {{ item.title }}
            </div>
            <div class="company-news-time">{{ item.created_at }}</div>
          </div>
        </div>
        <Pagination @change="pageChanged"
                    :current="1"
                    :pageSize="10"
                    :hideOnSinglePage="true"
                    :total="parseInt(dataList.total)"
                    :showQuickJumper="false"
                    :showTotal="false"
                    placement="center" />
        <!-- <PageNav @pageChanged="pageChanged"
                 :max="calcMaxPage(dataList.total)"
                 ref="page" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import PageNav from "@/components/PageNav.vue";
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/Pagination.vue"
import {
  fetchBanner,
  companyCategory,
  paperList
} from "@/network/API.js";

export default {
  components: {
    PageNav,
    SearchBar,
    Pagination
  },
  data() {
    return {
      banners: [],
      categorys: [],
      selCategory: {},
      code: "notification",
      dataList: {},
      subCode: "",
      curIdx: 0,

      searchKey: "",
      pageNumber: 1,
      total: 1,
    };
  },
  created() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
    }
    this.getBanner();
    // this.getTypes()
    this.getDataList();
  },
  methods: {
    async getBanner() {
      let data = await paperList({
        code: this.code,
        is_top: 1
      });
      this.banners = data.list;
    },
    toDetail(linkto) {
      this.$router.push({
        name: "CompanyArticle",
        params: {
          code: this.code,
          id: linkto,
        },
      });
    },
    async getTypes() {
      let data = await companyCategory(this.code);
      this.categorys = data;
      if (data.length > 0) {
        this.curCategory = "";
        this.getDataList();
      }
    },
    changeCategory(item) {
      this.curCategory = item;
      this.pageNumber = 1;
      this.getDataList();
    },
    async getDataList() {
      if (this.$refs.page) {
        this.$refs.page.pageNumber = this.pageNumber;
      }
      let data = await paperList({
        code: this.code,
        page: this.pageNumber,
        page_size: 10,
        keyword: this.searchKey,
      });
      this.dataList = data;
    },
    toSearch(val) {
      this.pageNumber = 1;
      this.searchKey = val;
      this.getDataList();
    },
    pageChanged(val) {
      this.pageNumber = val.page;
      this.getDataList();
    },
    changeIndicator(index) {
      this.curIdx = index;
    },
  },
};
</script>

<style scoped>
.home-banner {
  margin-bottom: -30px;
}


/* 轮播图 */
.carousel-inner {
  width: 100%;
  margin-bottom: 30px;
}

.carousel-inner .company-news-item {
  margin: 0;
  background: linear-gradient(
    123deg,
    rgba(191, 216, 255, 0.5) 4%,
    rgba(191, 216, 255, 0.5) 100%
  );
}

/* 选项卡 */

.nav-pills.nav-blue .nav-link {
  margin: 0;
  margin-right: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.nav-pills.nav-blue .nav-link:hover {
  color: #3b75ea;
}

.nav-pills.nav-blue .nav-link.active {
  background: linear-gradient(287deg, #3b75ea 1%, #3b9ae4 100%);
  color: #fff;
  border-radius: 50px;
  border: none;
}

.container {
  position: relative;
}
</style>