<template>
    <nav>
        <ul class="pagination justify-content-center">
            <li class="page-item" @click="changePage(pageNumber-1<=1?1:pageNumber-1)">
                <a class="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li v-for="(item, index) in maxPage" :key="index" :class="['page-item', pageNumber==index+1?'active':'']"
                @click="changePage(index+1)"><a class="page-link">{{index+1}}</a>
            </li>
            <li class="page-item" @click="changePage(pageNumber+1>=maxPage ? maxPage : pageNumber+1)">
                <a class="page-link" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        props: {
            max: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                pageNumber: 1,
                maxPage: 1,
            }
        },
        watch: {
            max: {
                handler(newValue) {
                    this.maxPage = newValue

                },
            },
            pageNumber: {
                handler(newValue) {
                    window.scrollTo(0, 0)
                }
            }
        },
        methods: {
            changePage(page) {
                if (page == this.pageNumber) return;
                this.pageNumber = page
                this.$emit('pageChanged', page)
            }
        }
    }
</script>

<style>

</style>